import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FlutterPostList from '../components/FlutterPostList'
import { Helmet } from "react-helmet";



const FlutterPage = class extends React.Component {


    render() {


        return(

            <div>

<Helmet>
                    <title>DavtechLab | Technologie | Education | Entrepreneuriat - Education - Flutter</title>
                    <meta name="description" content="Développez vos compétences en développement grâce au framework Flutter de Google" />
                    <meta name="og:image" content="assets/pages/img/frontend-slider/technologie1.png"/>        

                </Helmet>

<Navbar />

<div className="main">
      <div className="container">
        <ul className="breadcrumb">
            <li><a href="/">Acceuil</a></li>
            <li className="active">Flutter</li>
        </ul>
        <div className="row margin-bottom-40">
          <div className="col-md-12 col-sm-12">
            <h1>Flutter</h1>
            <div className="content-page">
                <div className="filter-v1">
                <ul className="mix-filter">
                    <li data-filter="all" className="filter active">Articles</li>
               
                  </ul>
               
                                    <div className="row mix-grid thumbnails">


                               
                                   <FlutterPostList/>

                                    
                          
                                     </div>


            </div>
          </div>
        </div>
      </div>
    </div>


            </div>

            <Footer/>
            </div>

        );
    
    }

}

export  default  FlutterPage;