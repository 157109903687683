
import React from "react"
import Markdown from "react-markdown"
import postlist from "../postsevent.json"

const  SidebarEventPostList = () => {
    const excerptList = postlist.map(post => {
        return post.meta_description.split(" ").slice(0, 10).join(" ") + "..."
        
    })

    

    if(postlist.length===0){

        return (

                                        
            <div><p>Pas d'évènements à venir</p></div>
            
            
            )


    }

      else if(postlist.length===1){
               
                        return (

                                        
                            <div>
                                <div class="recent-news margin-bottom-10">

                                        <div class="row margin-bottom-10">
                                                    <div class="col-md-4">
                                                        <img alt={postlist[0].title} src= {postlist[0].image} class="img-responsive"/>                    
                                                    </div>
                                                    <div class="col-md-8 recent-news-inner">
                                                    <a  href={`posts/event/${postlist[0].id}`} >  <h3 class="require" style={{fontWeight: 'bold'}}>{postlist[0].title}</h3></a>
                                                                <Markdown source={excerptList[0]} escapeHtml={false} /> 
                                                    </div>                        
                                            </div>


                            </div>
                            </div>
                        )
                    
                    } else if(postlist.length===2){


                        return (

                            <div>
                            <div class="recent-news margin-bottom-10">

                                    <div class="row margin-bottom-10">
                                                <div class="col-md-4">
                                                    <img alt={postlist[0].title} src= {postlist[0].image} class="img-responsive"/>                    
                                                </div>
                                                <div class="col-md-8 recent-news-inner">
                                                <a c href={`posts/event/${postlist[0].id}`} >  <h3 class="require" style={{fontWeight: 'bold'}}>{postlist[0].title}</h3></a>
                                                            <Markdown source={excerptList[0]} escapeHtml={false} /> 
                                                </div>                        
                                        </div>


                                        <div class="row margin-bottom-10">
                                                <div class="col-md-4">
                                                    <img alt={postlist[1].title} src= {postlist[1].image} class="img-responsive"/>                    
                                                </div>
                                                <div class="col-md-8 recent-news-inner">
                                                <a c href={`posts/event/${postlist[1].id}`} >  <h3 class="require" style={{fontWeight: 'bold'}}>{postlist[1].title}</h3></a>
                                                            <Markdown source={excerptList[1]} escapeHtml={false} /> 
                                                </div>                        
                                        </div>

                        </div>
                        </div>

                        )



                    } else if(postlist.length>=3){


                        return (

                            <div>
                            <div class="recent-news margin-bottom-10">

                                    <div class="row margin-bottom-10">
                                                <div class="col-md-4">
                                                    <img alt={postlist[0].title} src= {postlist[0].image} class="img-responsive"/>                    
                                                </div>
                                                <div class="col-md-8 recent-news-inner">
                                                <a c href={`posts/event/${postlist[0].id}`} >  <h3 style={{fontWeight: 'bold'}} class="require">{postlist[0].title}</h3></a>
                                                            <Markdown source={excerptList[0]} escapeHtml={false} /> 
                                                </div>                        
                                        </div>


                                        <div class="row margin-bottom-10">
                                                <div class="col-md-4">
                                                    <img alt={postlist[1].title} src= {postlist[1].image} class="img-responsive"/>                    
                                                </div>
                                                <div class="col-md-8 recent-news-inner">
                                                <a c href={`posts/event/${postlist[1].id}`} >  <h3 style={{fontWeight: 'bold'}} class="require">{postlist[1].title}</h3></a>
                                                            <Markdown source={excerptList[1]} escapeHtml={false} /> 
                                                </div>                        
                                        </div>

                                        <div class="row margin-bottom-10">
                                                <div class="col-md-4">
                                                    <img alt={postlist[2].title} src= {postlist[2].image} class="img-responsive"/>                    
                                                </div>
                                                <div class="col-md-8 recent-news-inner">
                                                <a c href={`posts/event/${postlist[2].id}`} >  <h3  style={{fontWeight: 'bold'}} class="require">{postlist[2].title}</h3></a>
                                                            <Markdown source={excerptList[2]} escapeHtml={false} /> 
                                                </div>                        
                                        </div>

                        </div>
                        </div>



                    
                        )

                }

           
            }
             


export default SidebarEventPostList