import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import CodingPostList from '../components/CodingPostList'
import { Helmet } from "react-helmet";



const CodingPage = class extends React.Component {


    render() {


        return(

            <div>

<Helmet>
                    <title>DavtechLab | Technologie | Education | Entrepreneuriat - Education - Programmation- JAVA - C - Python -Bureautique</title>
                    <meta name="description" content="Développez vos compétences en programation et en bureautique" />
                    <meta name="og:image" content="assets/pages/img/frontend-slider/technologie1.png"/>        

                </Helmet>

<Navbar />

<div className="main">
      <div className="container">
        <ul className="breadcrumb">
            <li><a href="/">Acceuil</a></li>
            <li className="active">Programmation et Bureautique</li>
        </ul>
        <div className="row margin-bottom-40">
          <div className="col-md-12 col-sm-12">
            <h1>Programmation et Bureautique</h1>
            <div className="content-page">
                <div className="filter-v1">
                  <ul className="mix-filter">
                    <li data-filter="all" className="filter active">All</li>
                    <li data-filter="C" className="filter">C</li>
                    <li data-filter="JAVA" className="filter">JAVA</li>
                    <li data-filter="Python" className="filter">C++</li>
                    <li data-filter="Bureautique" className="filter">Bureautique</li>
                  </ul>
                                    <div className="row mix-grid thumbnails">


                               
                                   <CodingPostList/>

                                    
                          
                                     </div>


            </div>
          </div>
        </div>
      </div>
    </div>


            </div>

            <Footer/>
            </div>

        );
    
    }

}

export  default  CodingPage;