import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Helmet } from "react-helmet";

const AboutPage = class extends React.Component {

    render() {

        return(

            <div>
 <Helmet>
                    <title>A propos</title>
                    <meta name="description" content="DavtechLab est un blog né de l'initiative de Junior DAVON jeune ingénieur des travaux informatiques
                                     voulant partager son savoir et ses compétences  avec d'autres personnes. Le principal but de ce blog est 
                                     d'aider  toute personne débutant ou amateur dans le domaine du digital à acquérir au travers les articles de solides notions
                                     en informatique, en marketing digital et en création d'entreprise." />
                </Helmet>

                <Navbar/>
                <div className="main">
                    <div className="container">
                        <ul className="breadcrumb pull-left">
                            <li><a href="/">Acceuil</a></li>
                            <li className="active">A propos</li>
                        </ul>
                <div class="row margin-bottom-40">
                    <div class="col-md-12 col-sm-12 ">

                        <div class="content-page">
                            <div class="row margin-bottom-30">
                                <div class="col-md-7 row-corporate-text-container ">
                                    <h2 class="no-top-space center row-corporate-text pull-left require">DavtechLab : qui sommes-nous ?</h2>
                                    <br/>
                                    <h4 className="row-corporate-text pull-left">DavtechLab est un blog né de l’initiative de Junior DAVON jeune ingénieur des travaux informatiques voulant partager son savoir et ses compétences avec d’autres personnes. Le principal but de ce blog est d’aider toute personne débutant ou amateur dans le domaine du numérique à acquérir au travers de nos articles de solides notions en informatique, en marketing digital et en création d’entreprise.</h4>

                                  
                                     <h2 class="no-top-space center row-corporate-text pull-left require">Nos produits et services</h2>

                                     <br/>
                                     <h4 className="row-corporate-text pull-left">Sur ce blog vous trouverez des articles bien détaillés accompagnés ou d’une vidéo de tutoriel.</h4>

                                     <br/>
                                     <h2 class="no-top-space center row-corporate-text pull-left require">Pour nous joindre</h2>

                                     <br/>
                                     <h4 className="row-corporate-text pull-left">Vous pouvez nous joindre par email <span className="require"> junior.davon@davtechlab.com</span> et sur <a href="https://twitter.com/davtechlab" target="_blank"><span className="require">twiter</span></a>.
                                     Nous sommes également présents sur  <a href="https://www.instagram.com/davtechlab/" target="_blank"><span className="require">Instagram</span> </a> où nous vous présentons nos prochains articles.</h4>


                                </div>


                                <div class="col-md-5 front-carousel">
                                    <div id="myCarousel" class="carousel slide">
                                        <div class="carousel-inner">
                                            <div class="item active">
                                                <img src="assets/pages/img/pics/flyer-davtechlab.png" alt="davtechlab"/>
                                                    <div class="carousel-caption">
                                                    </div>
                                            </div>
                                       
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>

                         
                        </div>
                    </div>
                    </div>

          


                        </div>
                    </div>


                            <Footer/>

            </div>
        );
    }
}

export  default  AboutPage;