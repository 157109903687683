import React from "react"
import Markdown from "react-markdown"
import postlist from "../postsevent.json"

const  EventPostList = () => {
    const excerptList = postlist.map(post => {
        return post.meta_description.split(" ").slice(0, 30).join(" ") + "..."
        
    })

    

    return (
        <div>


            {postlist.length && 
                postlist.map((post, i) => {
    return (

                    
        <div>

                                     <div className="col-md-4 col-sm-6 mix categ  mix_all "style={{display: "block",opacity: 1}}>
                                        <div class="mix-inner">
                                          <img alt={post.title} src= {post.image} class="img-responsive"/>
                                          <div class="mix-details">
                                               <h4>{post.title}</h4>
                    <Markdown source={excerptList[i]} escapeHtml={false} />                                                    
                    <a class="mix-link" href={`posts/event/${post.id}`} >Lire <i class="fa fa-eye"></i></a>
                                          </div>    
                                          </div>                                    
                                      </div>


         </div>
    )

                })
            }

                </div>
    )
}

export default EventPostList