import React from "react"
import { Redirect } from "react-router-dom"
import Markdown from "react-markdown"
import postlist from "../postsweb.json"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import PostSideBar from "../components/PostSideBar"
import { Helmet } from "react-helmet";
const Post = (props) => {
    const validId = parseInt(props.match.params.id)
    if (!validId) {
        return <Redirect to="/404" />
    }
    const fetchedPost = {}
    let postExists = false
    postlist.forEach((post, i) => {
        if (validId === post.id) {
            fetchedPost.id=post.id ? post.id : "No id given"
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.seo_title=post.seo_title ? post.seo_title :"No title given" 
            fetchedPost.meta_description=post.meta_description ? fetchedPost.meta_description : "No title given" 
            fetchedPost.date = post.date ? post.date : "No date given"
            fetchedPost.author = post.author ? post.author : "No author given"
            fetchedPost.content = post.content ? post.content : "No content given"
            fetchedPost.section = post.section ? post.section : "No content given"
            fetchedPost.image=post.image ? post.image : "No content given"
            fetchedPost.video=post.video ? post.video : "No content given"
            postExists = true
        }
    })
    if (postExists === false) {
        return <Redirect to="/404" />
    }


    return (
        <div>

                <Helmet>
                    <title>{fetchedPost.seo_title}</title>
                    <meta name="description" content={fetchedPost.meta_description} />
                    <meta name="og:image" content={fetchedPost.image} />
                </Helmet>
                <Navbar/>

                <div className="main">
                    <div className="container">
                        <ul className="breadcrumb pull-left">
                            <li><a href="">Acceuil</a></li>
                            <li><a href={"section/education/web"}>Web</a></li>
                            <li className="active">{fetchedPost.title}</li>
                        </ul>
                        <div className="row margin-bottom-40">
                            <div className="col-md-12 col-sm-12">
                                <div className="content-page">
                                    <div className="row">
                                        <div className="col-md-9 col-sm-9 blog-item" style={{fontSize:'18px',fontFamily:'Source Sans Pro',color:'#333'}}>
                                            <h1 className="active"><a href="javascript:;" style={{color:"#b21e1e"}}>{fetchedPost.title}</a></h1>
                                           
                                            <ul className="blog-info">
                                                <li><i className="fa fa-user"></i> {fetchedPost.author}</li>
                                                <li><i className="fa fa-calendar"></i> {fetchedPost.date}</li>
                                            </ul>

                                            <div class="sharethis-inline-share-buttons" data-url={"http://davtechlab.com/posts/web/"+fetchedPost.id} data-title={fetchedPost.title}></div>                                            <br/><br/>
                                            {fetchedPost.video==="pasvideo\r" ?
                                            <div className="blog-item-img">
                                                <div className="front-carousel">
                                                    <div id="myCarousel" className="carousel slide">
                                                        <div className="carousel-inner">


                                                            <div className="item active">
                                                                <img src= {fetchedPost.image} alt={fetchedPost.title}/>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
: <div  ><iframe  height="500"  style={{width: "100%"}} src={fetchedPost.video} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>}
                                            
                                          <br/>  
                                            <Markdown source={fetchedPost.content} escapeHtml={false} />

                                            


                                        </div>

                                        <PostSideBar/>



                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>


            </div>
    )
}

export default Post