import React from 'react'
const Footer = class extends React.Component {

    render() {

        return(

            <div>
                <div class="pre-footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 col-sm-6 pre-footer-col">
                                <h2  style={{color: "white"}}>A propos de ce blog</h2>
                                <h5 style={{color: "white"}}>DavtechLab est un blog qui  partage avec vous des connaissances sur les nouvelles technologies et le
                                 but final est d’amener nos visiteurs à entreprendre dans le secteur du numérique ou dans tout autre business.</h5>


                            </div>

                            <div class="col-md-4 col-sm-6 pre-footer-col">


                            </div>

                            <div class="col-md-4 col-sm-6 pre-footer-col">
                                <h2 style={{color: "white"}}>Contacts</h2>
                                <h5 style={{color: "white"}} className="margin-bottom-40">
                                    Email: junior.davon@davtechlab.com<br/>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 col-sm-4 padding-top-10">
                                2020 © DavtechLab. Tous droits réservés. <a href="/mentions-legales">Mentions légales</a>
                            </div>



                            <div class="col-md-4 col-sm-4 text-right">
                            <div class="sharethis-inline-follow-buttons"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default Footer