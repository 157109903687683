import React from 'react'
import NewsLetter from './NewsLetter';
import SidebarEventPostList from './SidebarEventPostList';
import SidebarRecentPostList from './SidebarRecentPostList';
import AdsFrame from './AdsFrame';


const PostSideBar=class extends React.Component {

    render() {

        return(

            <div>

<div className="col-md-3 col-sm-3 blog-sidebar">


 <AdsFrame/>
<br/><br/>
<NewsLetter/>
<h2 className="no-top-space">Evènements à venir!</h2>

<br/>
<SidebarEventPostList/>

<h2 className="no-top-space">Articles récents!</h2>

<br/>
<SidebarRecentPostList/>


                                           


                                            <div class="blog-tags margin-bottom-20">
                    <h2>Sections</h2>
                    <ul>
                      <li><a href="/section/technologie"><i class="fa fa-tags"></i>Technologie</a></li>
                      <li><a href="/section/education/web"><i class="fa fa-tags"></i>Web</a></li>
                      <li><a href="/section/education/flutter"><i class="fa fa-tags"></i>Flutter</a></li>
                      <li><a href="/section/education/programmation-bureautique"><i class="fa fa-tags"></i>Programmation & Bureautique</a></li>
                      <li><a href="/section/entrepreneuriat"><i class="fa fa-tags"></i>Entrepreunariat</a></li>
                      <li><a href="/section/event"><i class="fa fa-tags"></i>Evènements</a></li>
                     
                    </ul>
                  </div>

                                            
                                        </div>



            </div>);
        }}


        export default PostSideBar;