import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import IndexPage from "./pages/index-page"
import EntrepreunariatPage from "./pages/entrepreneuriat-page"
import TechnologiePage from "./pages/technologie-page"
import AboutPage from "./pages/about-page"
import Error404Page from "./pages/404-page"
import ThanksPage from "./pages/thanks-page"
import PostEntrepreneuriat from './pages/post-entrepreneuriat-page';
import PostTechnologie from './pages/post-technologie-page';
import PostCoding from './pages/post-coding-page';
import PostDesign from './pages/post-design-page';
import PostWeb from './pages/post-web-page';
import PostFlutter from './pages/post-flutter-page';
import WebPage from './pages/web-page';
import CodingPage from './pages/coding-page';
import DesignPage from './pages/design-page';
import FlutterPage from './pages/flutter-page';
import PostEvent from './pages/post-event-page';
import EventPage from './pages/event-page';
import MentionLegalePage from './pages/mentions-legales-page';

function App() {


    return (
        <div className="App">

            <Router>
                <Switch>
                    <Route exact path="/">
                        <IndexPage/>
                    </Route>
                    <Route exact path="/section/technologie">
                        <TechnologiePage/>

                    </Route>

               

                    <Route exact path="/section/education/web">
                        <WebPage/>

                    </Route>

                    <Route exact path="/section/education/programmation-bureautique">
                        <CodingPage/>

                    </Route>
                    <Route exact path="/section/education/design">
                        <DesignPage/>

                    </Route>

                    <Route exact path="/section/education/flutter">
                        <FlutterPage/>

                    </Route>

                    <Route exact path="/section/event">
                        <EventPage/>

                    </Route>

                    <Route exact path="/mentions-legales">
                        <MentionLegalePage/>

                    </Route>

                    <Route exact path="/posts/entrepreneuriat/:id" render={props => <PostEntrepreneuriat {...props} />}/>
                    <Route exact path="/posts/technologie/:id" render={props => <PostTechnologie {...props} />}/>
                    <Route exact path="/posts/programmation-bureautique/:id" render={props => <PostCoding {...props} />}/>
                    <Route exact path="/posts/design/:id" render={props => <PostDesign {...props} />}/>
                    <Route exact path="/posts/web/:id" render={props => <PostWeb {...props} />}/>
                    <Route exact path="/posts/flutter/:id" render={props => <PostFlutter {...props} />}/>
                    <Route exact path="/posts/event/:id" render={props => <PostEvent {...props} />}/>





                    <Route exact path="/section/entrepreneuriat/">
                        <EntrepreunariatPage/>
                    </Route>
                    <Route exact path="/about/">
                        <AboutPage/>
                    </Route>

                    <Route exact path="/thanks/">
                        <ThanksPage/>
                    </Route>


                    <Route path="*">
                        <Error404Page/>
                    </Route>

                </Switch>
            </Router>

        </div>
    );
}

export default App;
