import React from 'react'

const AdsFrame = class extends React.Component {

    render() {

        return(

            <div>

<div class="col-md-5 front-carousel" style={{width: 340}}>
                  <div id="myCarousel" class="carousel slide">
                    <div class="carousel-inner">
                      <div class="item active">
                      <a href="https://bour.so/Ek59IUcctS" target="_blank"> 
                        <img src="assets/pages/img/pics/boursorama-banque.png" alt="" />

                        </a>
                       
                      </div>
                    
                    
                    </div>
                    
                  </div>                
                </div>

            </div>

        )
    }

}

export default AdsFrame