import React from "react"
import Markdown from "react-markdown"
import posttechnologielist from "../poststechnologie.json"
import posteventlist from "../postsevent.json"
import postentrepreneuriatlist from "../postsentrepreneuriat.json"
import postflutterlist from "../postsflutter.json"
import postweblist from "../postsweb.json"

const IndexPostList = () => {
    const excerpttechnologiepostcontent = posttechnologielist[0].meta_description.split(" ").slice(0, 20).join(" ") + "..."
    const excerpteventpostcontent = posteventlist[0].meta_description.split(" ").slice(0, 20).join(" ") + "..."
    const excerptentrepreneuriatpostcontent = postentrepreneuriatlist[0].meta_description.split(" ").slice(0, 20).join(" ") + "..."
    const excerptflutterpostcontent = postflutterlist[0].meta_description.split(" ").slice(0, 20).join(" ") + "..."
    const excerptwebpostcontent = postweblist[0].meta_description.split(" ").slice(0, 20).join(" ") + "..."


    return (
        <div>
          

          <div className="col-md-4 col-sm-6 mix categ  mix_all "style={{display: "block",opacity: 1}}>
                                        <div class="mix-inner">
                                          <img alt={posteventlist[0].title} src= {posteventlist[0].image} class="img-responsive"/>
                                          <div class="mix-details">
                                               <h4>{posteventlist[0].title}</h4>
                    <Markdown source={excerpteventpostcontent} escapeHtml={false} />                                                    
                    <a class="mix-link" href={`posts/event/${posteventlist[0].id}`} >Lire <i class="fa fa-eye"></i></a>
                                          </div>    
                                          </div>                                    
                                      </div>


                                      <div className="col-md-4 col-sm-6 mix categ  mix_all "style={{display: "block",opacity: 1}}>
                                        <div class="mix-inner">
                                          <img alt={postentrepreneuriatlist[0].title} src= {postentrepreneuriatlist[0].image} class="img-responsive"/>
                                          <div class="mix-details">
                                               <h4>{postentrepreneuriatlist[0].title}</h4>
                    <Markdown source={excerptentrepreneuriatpostcontent} escapeHtml={false} />                                                    
                    <a class="mix-link" href={`posts/entrepreneuriat/${postentrepreneuriatlist[0].id}`} >Lire <i class="fa fa-eye"></i></a>
                                          </div>    
                                          </div>                                    
                                      </div>

<div className="col-md-4 col-sm-6 mix categ  mix_all "style={{display: "block",opacity: 1}}>
                                        <div class="mix-inner">
                                          <img alt={posttechnologielist[0].title} src= {posttechnologielist[0].image} class="img-responsive"/>
                                          <div class="mix-details">
                                               <h4>{posttechnologielist[0].title}</h4>
                    <Markdown source={excerpttechnologiepostcontent} escapeHtml={false} />                                                    
                    <a class="mix-link" href={`posts/technologie/${posttechnologielist[0].id}`} >Lire <i class="fa fa-eye"></i></a>
                                          </div>    
                                          </div>                                    
                                      </div>




    

         

                </div>
    )
}

export default IndexPostList