import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import WebPostList from '../components/WebPostList'
import { Helmet } from "react-helmet";



const WebPage = class extends React.Component {


    render() {


        return(

            <div>

<Helmet>
                    <title>DavtechLab | Technologie | Education | Entrepreneuriat - Education - Web - Spring boot - Angular - ReactJS</title>
                    <meta name="description" content="Des cours et de tutoriels pour apprendre la programmation web grâce aux outils et bibliothèques modernes (Spring Boot, ReactJS, Angular ...) " />
                    <meta name="og:image" content="assets/pages/img/frontend-slider/technologie1.png"/>        

                </Helmet>

<Navbar />

<div className="main">
      <div className="container">
        <ul className="breadcrumb">
            <li><a href="/">Acceuil</a></li>
            <li className="active">Web</li>
        </ul>
        <div className="row margin-bottom-40">
          <div className="col-md-12 col-sm-12">
            <h1>Web</h1>
            <div className="content-page">
                <div className="filter-v1">
                  <ul className="mix-filter">
                    <li data-filter="all" className="filter active">All</li>
                    <li data-filter="ReactJS" className="filter">ReactJS</li>
                    <li data-filter="Angular" className="filter">Angular</li>
                    <li data-filter="Spring boot" className="filter">Spring boot</li>
                  </ul>
                                    <div className="row mix-grid thumbnails">


                               
                                   <WebPostList/>

                                    
                          
                                     </div>


            </div>
          </div>
        </div>
      </div>
    </div>


            </div>

            <Footer/>
            </div>

        );
    
    }

}

export  default  WebPage;