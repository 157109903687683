
import React from "react"
import { Redirect } from "react-router-dom"
import Markdown from "react-markdown"
import postlist from "../postsevent.json"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import PostSideBar from "../components/PostSideBar"
import { Helmet } from "react-helmet";

const Post = (props) => {
    const validId = parseInt(props.match.params.id)
    if (!validId) {
        return <Redirect to="/404" />
    }
    const fetchedPost = {}
    let postExists = false
    postlist.forEach((post, i) => {
        if (validId === post.id) {
            fetchedPost.id =post.id ? post.id : "No id given"
            fetchedPost.title = post.title ? post.title : "No title given"
            fetchedPost.seo_title=post.title ? post.title : "No seo title given"
            fetchedPost.meta_description=post.meta_description ? post.meta_description : "No meta description given"
            fetchedPost.date = post.date ? post.date : "No date given"
            fetchedPost.startdate=post.startdate ? post.startdate : "No given"
            fetchedPost.finishdate=post.finishdate ? post.finishdate : "No given"
            fetchedPost.author = post.author ? post.author : "No author given"
            fetchedPost.content = post.content ? post.content : "No content given"
            fetchedPost.section = post.section ? post.section : "No content given"
            fetchedPost.image=post.image ? post.image : "No content given"
            postExists = true
        }
    })
    if (postExists === false) {
        return <Redirect to="/404error" />
    }
    return (
        <div>

<Helmet>
                    <title>{fetchedPost.seo_title}</title>
                    <meta name="description" content={fetchedPost.meta_description} />
                    <meta name="og:image" content={fetchedPost.image} />
                </Helmet>
                <Navbar/>

                <div className="main">
                    <div className="container">
                        <ul className="breadcrumb pull-left">
                            <li><a href="">Acceuil</a></li>
                            <li><a href={"section/technologie"}>Technologie</a></li>
                            <li className="active">{fetchedPost.title}</li>
                        </ul>
                        <div className="row margin-bottom-40">
                            <div className="col-md-12 col-sm-12">
                                <div className="content-page">
                                    <div className="row">
                                        <div className="col-md-9 col-sm-9 blog-item" style={{fontSize:'18px',fontFamily:'Source Sans Pro',color:'#333'}}>
                                            <h1 className="active"><a href="javascript:;" style={{color:"#b21e1e" ,fontWeight:'bold' }}>{fetchedPost.title}</a></h1>

                                            <ul className="blog-info">
                                                <li><i className="fa fa-user"></i> {fetchedPost.author}</li>
                                                <li><i className="fa fa-calendar"></i>Date du post: {fetchedPost.date}</li>
                                                <li><i className="fa fa-calendar"></i>Date début: {fetchedPost.startdate}</li>
                                                <li><i className="fa fa-calendar"></i>Date Fin: {fetchedPost.finishdate}</li>

                                            </ul>
                                            <div class="sharethis-inline-share-buttons" data-url={"http://davtechlab.com/posts/event/"+fetchedPost.id} data-title={fetchedPost.title}></div>                                            <br/><br/>

                                            <div className="blog-item-img">
                                                <div className="front-carousel">
                                                    <div id="myCarousel" className="carousel slide">
                                                        <div className="carousel-inner">


                                                            <div className="item active">
                                                                <img  src= {fetchedPost.image} alt={fetchedPost.title}/>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <Markdown source={fetchedPost.content} escapeHtml={false} />


                                        </div>

                                        <PostSideBar/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>


            </div>
    )
}

export default Post