import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import { Helmet } from "react-helmet";

const MentionLegalPage = class extends React.Component {
 
    render() {
        return(


 <div>

<Helmet>
                    <title>Mentions légales</title>
                 

                </Helmet>

                <Navbar active="active"/>
               
    <div class="main">
      <div class="container">
        <ul class="breadcrumb">
            <li><a href="/">Accueil</a></li>
            <li class="active">Mentions légales</li>
        </ul>
        <div class="row margin-bottom-40">
          <div class="col-md-12 col-sm-12">
            <h1>Mentions légales</h1>
            <div class="content-page">

            <h2 style={{fontStyle: 'bold'}}>Éditeur et Responsable de la publication</h2>
            <p className="ml-contenu">Les propos tenus sur ce site ne représentent que et uniquement l’opinion de leur auteur, 
                et n’engagent pas les sociétés, entreprises ou collectifs auxquels il contribue ou dont il peut être associé ou prestataire.</p>

<br/>
                <h2 style={{fontStyle: 'bold'}}>Hébergement</h2>
            <p className="ml-contenu">Ce site est hébergé par Netlify Inc, 2325 3rd Street, Suite 215 San Francisco, Californie 94107 États-Unis d’Amérique.</p>



            <br/>
                <h2 style={{fontStyle: 'bold'}}>Responsabilité</h2>
            <p className="ml-contenu">Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres sites internet ou ressources présentes sur le réseau internet, ne sauraient engager la responsabilité de l’éditeur du site.</p>

            <br/>
                <h2 style={{fontStyle: 'bold'}}>Données personnelles</h2>
            <p className="ml-contenu">Les données personnelles collectées par ce site sont uniquement destinées à un usage interne et pour vous proposer un service de publicité ciblée en ligne. Ces données sont collectées soit automatiquement lors de la connexion de votre terminal au site web soit grâce à notre formulaire d’inscription à la newsletter. Vos données personnelles ne seront ni cédées ni vendues à des tiers.</p>


<br/>
                <h2 style={{fontStyle: 'bold'}}>Cookies</h2>
            <p className="ml-contenu">Lorsque vous visitez le site, des cookies peuvent être déposés sur votre ordinateur, notamment ceux de Google Analytics.
Ces cookies sont utilisés dans un but statistique. Ils ne contiennent aucune information personnelle et ne permettent pas de vous identifier personnellement. Vous pouvez choisir de refuser ces cookies en paramétrant votre navigateur.</p>

<br/>
                <h2 style={{fontStyle: 'bold'}}>Conditions d’utilisation</h2>
            <p className="ml-contenu">Le site a été réalisé par Junior DAVON. Ce site est géré intégralement par Davtechlab et le but de ce blog est de partager avec vous des connaissances sur les nouvelles technologies et l’entrepreneuriat.</p>



<br/>
                <h2 style={{fontStyle: 'bold'}}>Licences, droits de reproduction</h2>
            <p className="ml-contenu">La reproduction du contenu du blog Davtechlab, en tout ou en partie, est interdite sans la permission écrite de l’auteur. Tout contenu sur ce site ne nous appartenant pas (photos, textes, images, logos, vidéos…) sont la propriété de leurs propriétaires respectifs.</p>
           
<h2 style={{fontStyle: 'bold'}}>Crédits</h2>
            <p className="ml-contenu">Ce site utilise des illustrations libres de droit issues du site <a style={{color: 'black'}} href="https://www.freepik.com/home">Freepik</a></p>
            <p className="ml-contenu">Ce site utilise des icônes libres de droit issues du site <a style={{color: 'black'}} href="https://www.flaticon.com/">FLATICON</a></p>
  
           
            </div>


            

            
                <Footer/>

            </div>
            </div>
            </div>
            </div>
            </div>
        );
    }

}

export  default  MentionLegalPage;