import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import TechnologiePostList from '../components/TechnologiePostList';
import { Helmet } from "react-helmet";



const TechnologiePage = class extends React.Component {


    render() {


        return(

            <div>

<Helmet>
                    <title>DavtechLab | Technologie | Education | Entrepreneuriat - Technologie</title>
                    <meta name="description" content=" L’actualité des nouvelles technologies partout dans le monde, des sujets sur la technologie dans le secteur de l’éducation, la santé, l’agriculture et aussi des sujets sur l’énergie de demain et l’Intelligence artificielle." />
                    <meta name="og:image" content="assets/pages/img/frontend-slider/technologie-banner.png"/>        

                </Helmet>

                <Navbar active="active"/>
                <div className="container-fluid flush-with-header davtechlab-header" >

                    <div className="row margin-bottom-40 row-corporate bg-light-1">
                        <div className="container">
                            <div className="hidden-xs col-sm-6 row-corporate-text-container">
                                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                <div className="row-corporate-text presentation-text-banner">
                                    <h1>Technologie<b className="require"> .</b></h1>
                                    <h4 className="wow fadeIn animated"
                                        style={{visibility: "visible", animationName: "fadeIn"}}>
                                      L’actualité des nouvelles technologies partout dans le monde, des sujets sur la technologie dans le secteur de l’éducation, la santé, l’agriculture et aussi des sujets sur l’énergie de demain et l’Intelligence artificielle.
                                       

                                    </h4>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                            <div className="davtechlab-banner">
                                <img className="img-responsive" src="assets/pages/img/frontend-slider/technologie-banner.png" alt="technologie"/>
                              </div>
                              <br/> <br/>  
                                <h4 className="wow fadeIn hidden-sm hidden-md hidden-lg animated presentation-text-banner"
                                    style={{visibility: "visible", animationName: "fadeIn"}}>L’actualité des nouvelles technologies partout dans le monde, des sujets sur la technologie dans le secteur de l’éducation, la santé, l’agriculture et aussi des sujets sur l’énergie de demain et l’Intelligence artificielle.</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <br/>
                <div className="main">
                    <div className="container">

                        <div className="row margin-bottom-40">
                            <div className="col-md-12 col-sm-12">
                            <div className="content-page">
                <div className="filter-v1">
                  <ul className="mix-filter">
                  <li data-filter="all" className="filter active">Articles</li>

                    
                  </ul>
                                    <div className="row mix-grid thumbnails">


                               
                                   <TechnologiePostList/>

                                    
                          
                                     </div>


            </div>
          </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer/>

            </div>

          
        );
    }
}

export  default  TechnologiePage;