import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import DesignPostList from '../components/DesignPostList'
import { Helmet } from "react-helmet";



const DesignPage = class extends React.Component {


 

  render() {


    return(

        <div>

<Helmet>
                <title>DavtechLab | Technologie | Education | Entrepreneuriat - Education - Design</title>
                <meta name="description" content="Développez vos compétences en Design" />
                <meta name="og:image" content="assets/pages/img/frontend-slider/technologie1.png"/>        

            </Helmet>

<Navbar />

<div className="main">
  <div className="container">
    <ul className="breadcrumb">
        <li><a href="/">Acceuil</a></li>
        <li className="active">Design</li>
    </ul>
    <div className="row margin-bottom-40">
      <div className="col-md-12 col-sm-12">
        <h1>Design</h1>
        <div className="content-page">
            <div className="filter-v1">
              <ul className="mix-filter">
                <li data-filter="all" className="filter active">Graphisme</li>
                <li data-filter="DesignUI" className="filter">Design UI</li>
                <li data-filter="DesignUx" className="filter">Design UX</li>
                <li data-filter="MotionDesign" className="filter">Motionn Design</li>
              </ul>
                                <div className="row mix-grid thumbnails">


                           
                               <DesignPostList/>

                                
                      
                                 </div>


        </div>
      </div>
    </div>
  </div>
</div>


        </div>

        <Footer/>
        </div>

    );

}


}

export  default  DesignPage;