import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const ThanksPage = class extends React.Component {

    render() {

        return(

            <div>

                <Navbar/>
                <div className="main">
                    <div className="container">
                        <div className="row margin-bottom-40">
                            <div className="col-md-12 col-sm-12">
                                <div className="content-page page-404">
                                    <div className="number">
                                        Merci
                                    </div>
                                    <div className="details">
                                        <h3>De nous avoir contacter. Nous rentrerons en contact avec vous dans les meilleurs délais.</h3>
                                        <p>
                                            <a href="/" className="link">Retour à la page d'acceuil</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>

            </div>
        );
    }
}

export  default  ThanksPage;