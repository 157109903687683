import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import IndexPostList from '../components/IndexPostList'
import { Helmet } from "react-helmet";

const IndexPage = class extends React.Component {

    render() {

        return(

            <div>

<Helmet>
                    <title>DavtechLab | Technologie | Education | Entrepreneuriat</title>
                    <meta name="description" content=" DavtechLab est un blog qui partage avec vous
                                        l’actualité sur les nouvelles technologies, des connaissances en
                                        développement d’application et en entrepreneuriat." />

                <meta name="og:image" content="assets/pages/img/frontend-slider/banner.png"/>        


                </Helmet>

                <Navbar active="active"/>

                <div className="container-fluid flush-with-header davtechlab-header" >

                    <div className="row margin-bottom-40 row-corporate bg-light-1">
                        <div className="container">
                            <div className="hidden-xs col-sm-6 row-corporate-text-container">
                                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                <div className="row-corporate-text  presentation-text-banner">
                                    <h1>L’excellence au service de l’innovation<b className="require"> .</b></h1>
                                    <h4  className="wow fadeIn animated " 
                                        style={{visibility: "visible", animationName: "fadeIn"}}>
                                        DavtechLab est un blog qui partage avec vous
                                        l’actualité sur les nouvelles technologies, des connaissances en
                                        développement d’application et en entrepreneuriat.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6" >
                                <div className="davtechlab-banner img-responsive">
                                <img className="img-responsive" src="assets/pages/img/frontend-slider/davtechlab-banner.png" alt="technologie|education|entrepreneuriat"/>
                                </div>
<br/> <br/>
                                    <h4 className="wow fadeIn hidden-sm hidden-md hidden-lg animated presentation-text-banner"
                                        style={{visibility: "visible", animationName: "fadeIn"}}>DavtechLab est un blog qui partage avec vous
                                        l’actualité sur les nouvelles technologies, des connaissances en
                                        développement d’application et en entrepreneuriat.</h4>
                            </div>
                        </div>
                    </div>
                </div>


                <br/>
                <div className="main">
                    <div className="container">
                        <div className="row service-box margin-bottom-40">
                            <div className="col-md-4 col-sm-4">
                                <div className="service-box-heading">
                                    <em><i className="fa fa-desktop red"></i></em>
                                    <span >Technologie</span>
                                </div>
                                <p className="features-text">Nous partageons avec vous l’actualité sur les nouvelles technologies ainsi que les évènements dans le domaine de  la tech à venir.</p>
                                <br/>

                                <img className="img-responsive" src="assets/pages/img/features/technologie.png" alt="Technologie"/>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="service-box-heading">
                                    <em><i className="fa fa-laptop  blue"></i></em>
                                    <span >Education au numérique</span>
                                </div>
                                <p className="features-text">Des tutoriels bien détaillés sur la programmation informatique et le partage de

                                    nos réalisations professionnelles.</p>
                                <br/>
                                <img className="img-responsive" src="assets/pages/img/features/education.png" alt="Education"/>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="service-box-heading">
                                    <em><i className="fa fa-check green "></i></em>
                                    <span  >Entrepreneuriat</span>
                                </div>
                                <p className="features-text">Nous abordons les thématiques  sur le
                                    développement personnel et l’auto-entrepreneuriat.</p>

                                <img alt="entrepreneuriat" className="img-responsive" src="assets/pages/img/features/entreprenariat.png"/>
                            </div>
                        </div>
                        <br/>

                        <h3 className="require"> Objectifs </h3>
                        <br/>

                        <img alt="Les objectifs de davtechab" className="img-responsive" src="assets/pages/img/objectifs-davtechlab.png" width="1200" />


<br/><br/><br/>

<br/>


<h3 className="require"> Articles récents</h3>
                        <br/>
<div className="filter-v1">
              
               
                                    <div className="row mix-grid thumbnails">

                               
                                   <IndexPostList/>

                          
                                     </div>


            </div>

           




                    </div>
                </div>

                <br/>

                <Footer/>

            </div>
        )

    }
}

export default IndexPage