import React from "react"
import Markdown from "react-markdown"
import posttechnologielist from "../poststechnologie.json"
import posteventlist from "../postsevent.json"
import postentrepreneuriatlist from "../postsentrepreneuriat.json"
import postflutterlist from "../postsflutter.json"
import postweblist from "../postsweb.json"

const SidebarRecentPostList = () => {
    const excerpttechnologiepostcontent = posttechnologielist[0].meta_description.split(" ").slice(0, 5).join(" ") + "..."
    const excerpteventpostcontent = posteventlist[0].meta_description.split(" ").slice(0, 5).join(" ") + "..."
    const excerptentrepreneuriatpostcontent = postentrepreneuriatlist[0].meta_description.split(" ").slice(0, 5).join(" ") + "..."
    const excerptflutterpostcontent = postflutterlist[0].meta_description.split(" ").slice(0, 5).join(" ") + "..."
    const excerptwebpostcontent = postweblist[0].meta_description.split(" ").slice(0, 5).join(" ") + "..."


    return (
        <div>

                                <div class="recent-news margin-bottom-10">

                                                <div class="row margin-bottom-10">
                                                            <div class="col-md-4">
                                                                <img alt={posttechnologielist[0].title} src= {posttechnologielist[0].image} class="img-responsive" style={{float: 'right'}}/>                    
                                                            </div>
                                                            <div class="col-md-8 recent-news-inner">
                                                            <a  href={`posts/technologie/${posttechnologielist[0].id}`} >  <h3 class="require" style={{fontWeight: 'bold'}}>{posttechnologielist[0].title}</h3></a>
                                                                        <Markdown source={excerpttechnologiepostcontent} escapeHtml={false} /> 
                                                            </div>                        
                                                    </div>

                                                    <div class="row margin-bottom-10">
                                                            <div class="col-md-4">
                                                                <img alt={postentrepreneuriatlist[0].title} src= {postentrepreneuriatlist[0].image} class="img-responsive"/>                    
                                                            </div>
                                                            <div class="col-md-8 recent-news-inner">
                                                            <a  href={`posts/entrepreneuriat/${postentrepreneuriatlist[0].id}`} >  <h3 class="require" style={{fontWeight: 'bold'}}>{postentrepreneuriatlist[0].title}</h3></a>
                                                                        <Markdown source={excerptentrepreneuriatpostcontent} escapeHtml={false} /> 
                                                            </div>                        
                                                    </div>

                                                    <div class="row margin-bottom-10">
                                                            <div class="col-md-4">
                                                                <img alt={posteventlist[0].title} src= {posteventlist[0].image} class="img-responsive"/>                    
                                                            </div>
                                                            <div class="col-md-8 recent-news-inner">
                                                            <a  href={`posts/event/${posteventlist[0].id}`} >  <h3 class="require" style={{fontWeight: 'bold'}}>{posteventlist[0].title}</h3></a>
                                                                        <Markdown source={excerpteventpostcontent} escapeHtml={false} /> 
                                                            </div>                        
                                                    </div>


                                </div>
                                        

        







    

         

                </div>
    )
}

export default SidebarRecentPostList