import React from 'react'

const NewsLetter = class extends React.Component {

    render() {

        return(

            <div>

<div id="mc_embed_signup" >
<h2>NEWSLETTER</h2>

<form action="https://davtechlab.us19.list-manage.com/subscribe/post?u=9c8f15558fdc7d67c186c2b9f&amp;id=dca493df2e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">

        <div className="newsletter">
            <h2 className="">Inscrivez-vous à notre newsletter !</h2>
            <p className="" >Ces informations sont uniquement utilisées pour vous envoyer nos derniers articles de blog ainsi que pour des publicités ciblées. Vous pouvez vous désabonner à tout moment en cliquant sur le lien dans le bas de page de nos e-mails. Pour obtenir plus d'informations <a href="/mentions-legales">Lire PLus.</a></p>

<div className="newsletter_form" >
<div class="mc-field-group">
	<label for="mce-EMAIL">Email </label>
	<input className="newsletter_input" type="email"  name="EMAIL" required id="mce-EMAIL"/>
</div>
	<div id="mce-responses" class="clear">
		<div class="response" id="mce-error-response" style={{display:"none"}}></div>
		<div class="response" id="mce-success-response" style={{display:"none"}}></div>
	</div>    
    <div style={{position: "absolute", left: -5000}} aria-hidden="true"><input type="text" name="b_9c8f15558fdc7d67c186c2b9f_dca493df2e" tabindex="-1" value=""/></div>
    <br/>
    <div ><input type="submit" style={{backgroundColor:"#b21e1e"}} value="Subscribe" name="subscribe"  id="mc-embedded-subscribe" class="button"/></div>
    <br/>

    </div>
    </div>
</div>
</form>
</div>

            </div>
        );
    
    
    }

}

export default NewsLetter
