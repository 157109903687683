import React from 'react'

const Navbar = class extends React.Component {



    render() {


        return (

            <div>


                {/*Debut Informations de contact header*/}


                <div class="pre-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 additional-shop-info">
                                <ul class="list-unstyled list-inline">
                                    <li><i class="fa fa-envelope-o"></i><span>junior.davon@davtechlab.com</span></li>
                                </ul>
                            </div>

                            <div class="col-md-6 col-sm-6 additional-nav">
                            <div class="sharethis-inline-follow-buttons"></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Fin Informations de contact header*/}


                {/*Debut header navigation*/}

                <div class="header">

                    <div class="container">

                        {/*Logo*/}

                        <a class="site-logo" href="/"><img src="assets/corporate/img/logos/logo-davtechlab.png" alt="DavtechLab"/></a>

                        <a href="javascript:void(0);" class="mobi-toggler"><i class="fa fa-bars"></i></a>

                        <div class="header-navigation pull-right font-transform-inherit">
                            <ul>
                                <li className="active"><a href="/">Accueil</a></li>

                                <li><a href="/section/technologie">Technologie</a></li>
                                <li class="dropdown">
              <a class="dropdown-toggle" data-toggle="dropdown" data-target="#" href="/section/education">
                Education
                
              </a>
                
              <ul class="dropdown-menu">
                <li class=""><a href="/section/education/web">Web</a></li>
                <li><a href="/section/education/design">Design</a></li>
                <li><a href="/section/education/flutter">Flutter</a></li>
                <li><a href="/section/education/programmation-bureautique">Programmation et Bureautique</a></li>
              </ul>
            </li>
                                <li><a href="/section/entrepreneuriat">Entrepreneuriat</a></li>
                                <li><a href="/section/event">Evènements</a></li>


                                <li><a href="/about">A propos</a></li>


                                <li class="menu-search">
                                    <span class="sep"></span>
                                    <i class="fa fa-search search-btn"></i>
                                    <div class="search-box">
                                        <form action="#">
                                            <div class="input-group">
                                                <input type="text" placeholder="Search" class="form-control"/>
                                                <span class="input-group-btn">
                      <button class="btn btn-primary" type="submit">Search</button>
                    </span>
                                            </div>
                                        </form>
                                    </div>
                                </li>
                            </ul>
                            {this.props.children}
                        </div>
                    </div>
                </div>

                {/*Fin header navigation*/}

            </div>


        );
    }

}


export default Navbar